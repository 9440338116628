import { ResponseForgotPassInterface } from './../types/ResponseForgotPassInterface';
import { defineStore } from 'pinia';
import { Login, Register, LoginResponse } from '../components/models';
import authService from '../services/AuthService';
import userService from '../services/UserService';
import { AxiosResponse } from 'axios';
import Cookie from '../utilities/Cookie';

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useAuth = defineStore('auth', {
  state: () => {
    return {
      logged: false,
      username: '',
      email: '',
      jwt: '',
      id: 0,
      role: 0,
      firstConnect: false,
    };
  },
  getters: {
    user: (state) => state,
    userId: (state) => state.id,
    getJwt: (state) => state.jwt,
    isLogged: (state) => state.logged,
    getUsername: (state) => state.username,
    getRole: (state) => state.role,
    getClearedTutorial: (state) => state.firstConnect
  },
  actions: {
    disconnect(): void {
      const cookie = new Cookie();
      cookie.purgeCookie('jwt');
      this.logged = false;
      this.username = '';
      this.email = '';
      this.jwt = '';
      this.id = 0;
      this.role = 0;
      this.firstConnect = false;
    },
    register(user: Register): Promise<AxiosResponse<Register>> {
      return authService.register(user);
    },
    setJwt(): void {
      const cookie = new Cookie();
      this.jwt = cookie.getCookie('jwt');
    },
    login(user: Login): Promise<AxiosResponse<LoginResponse>> {
      return authService.login(user);
    },
    async getUser() {
      await userService.getUser().then(
        (resp) => {
          this.logged = true;
          this.username = resp.data.username;
          this.email = resp.data.email;
          this.jwt = resp.data.jwt;
          this.id = resp.data.id;
          this.role = resp.data.role.id;
          this.firstConnect = resp.data.firstConnect;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    async getUserFromCookie(): Promise<void> {
      if (!this.jwt) {
        const cookie = new Cookie();
        this.jwt = cookie.getCookie('jwt');
        if (this.jwt) await this.getUser();
      }
    },
    forgotPass(email: string): Promise<AxiosResponse<ResponseForgotPassInterface>>{
      return authService.forgotPass(email)
    },
    resetPass(code: string, password: string, cPassword: string): Promise<AxiosResponse<LoginResponse>>{
      return authService.resetPass(code, password, cPassword )
    }
  },
});
