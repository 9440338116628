import { Notify } from 'quasar'

export default class Notif{
    timeout = 2000;

    public successNotif(message: string) {
        Notify.create({
            message: message,
            type: 'positive',
            timeout: this.timeout
          })
    }
    public errorNotif(message: string) {
        Notify.create({
            message: message,
            type: 'negative',
            timeout: this.timeout
          })
    }
    public handleError(error: number) {
        if(error === 400) this.errorNotif('Identifiant ou mot de passe incorrect');
        if(error === 401) this.errorNotif('Vous n\'êtes pas authentifié');
        if(error === 403) this.errorNotif('Accès refusé');
        if(error === 404) this.errorNotif('Ressource non trouvée');
        if(error === 500) this.errorNotif('Une erreur est survenue');
    }
}