import { ResponseForgotPassInterface } from './../types/ResponseForgotPassInterface';
import { AxiosResponse } from 'axios'
import { Register, Login, LoginResponse} from '../components/models';
import { api } from '../boot/axios'

// const apiUrl = process.env.API_URL;

class AuthService {
  register(register: Register): Promise<AxiosResponse<Register>> {
    return api.post('/api/auth/local/register', JSON.stringify(register) )
  }
  login(login: Login): Promise<AxiosResponse<LoginResponse>> {
    return api.post('/api/auth/local', JSON.stringify(login) )
  }
  forgotPass(email: string): Promise<AxiosResponse<ResponseForgotPassInterface>>{
    return api.post('/api/auth/forgot-password', JSON.stringify({email: email}), {headers: { 'Content-type': 'application/json' }})
  }
  resetPass(code: string, pass: string, cpass: string): Promise<AxiosResponse<LoginResponse>>{
    return api.post('/api/auth/reset-password', JSON.stringify({ code: code, password: pass, passwordConfirmation: cpass}), {headers: { 'Content-type': 'application/json' }})
  }
}
export default new AuthService();
