export interface AuthStateInterface {
  logged: boolean;
  username: string;
  email: string;
  jwt: string;
}

function state(): AuthStateInterface {
  return {
    logged: false,
    username: '',
    email: '',
    jwt: ''
  };
}

export default state;
