import { AxiosResponse } from 'axios'
import { Notif } from './../components/models';
import { api } from '../boot/axios';

// const apiUrl = process.env.API_URL;

class NotifService {

  getNotifs(): Promise<AxiosResponse<{data : Notif[]}>> {
    return api.get('/api/notifications?filters[read][$eq]=false')
  }

  readNotif(id: string): Promise<AxiosResponse<{data : Notif[]}>> {
    return api.put('/api/notifications/'+ id, JSON.stringify({ data:{read: true}}))
  }
}
export default new NotifService();
