
import { defineComponent, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useNotif } from './pinia/notif';
import { useAuth } from 'src/pinia/auth';
import Notif from './utilities/Notif';

export default defineComponent({
  name: 'App',
  setup() {
    const route = useRoute();
    const router = useRouter();

    watch(() => route.query, () => {
      const notif = new Notif;

      if(route.query.successmsg) notif.successNotif(route.query.successmsg.toString());
      if(route.query.errormsg) notif.errorNotif(route.query.errormsg.toString());

      void router.replace({'query' : undefined});
    })

    return {
      notif: useNotif(),
      auth: useAuth()
    }
  },

});
