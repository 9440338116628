import { defineStore } from 'pinia';
import { Notif } from './../components/models';
import notifService from '../services/NotifService';

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useNotif = defineStore('notif', {
  state: () => {
    return {
      notifs: [] as Notif[],
      nbNotif: 0
    };
  },
  getters: {
    getNotifNumber: (state) => state.notifs.length,
    getNotifs: (state) => state.notifs
  },
  actions: {
    setNotifs() {
      return notifService.getNotifs().then(
        resp => {
          this.notifs = resp.data.data;
          this.nbNotif = this.notifs.length
        }
      )
    }
  }
})
