import authService from '../../services/AuthService';
import { ActionTree } from 'vuex';
import { StateInterface } from '../index';
import { AuthStateInterface } from './state';
import { Login, Register } from '../../components/models';
import Cookie from '../../utilities/Cookie';

const actions: ActionTree<AuthStateInterface, StateInterface> = {
  register({}, user: Register) : void {

    authService.register(user)
        .then(
            () => {
                window.location.href = 'login';
            },
            error => {
                // commit('registerFailure', error);
                // dispatch('alert/error', error, { root: true });
                console.log(error);
            }
        );
    },
    login({}, user: Login) : void {
        authService.login(user)
            .then(
                data  => {
                    this.commit('auth/loginMutation', data)
                    const cookie = new Cookie;
                    cookie.setCookie('jwt', data.data.jwt, 30);
                },
                error => {
                    console.log(error);
                }
            )
    },

    // retrieveUser({}, jwt: string) {

    // }
};

export default actions;
