export default class Cookie {
  public setCookie(name: string, value: string, time: number, type = 'minutes') {
    const expiryDate = new Date();
    if(type == 'minutes')
    {
      expiryDate.setMinutes(expiryDate.getMinutes() + time);
    }
    else if(type == 'months')
    {
      expiryDate.setMonth(expiryDate.getMonth() + time);
    }
    document.cookie = name + '=' + value + '; expires=' + expiryDate.toString() + '; path=/';
  }
  public getCookie(name: string): string {
    const match = document.cookie.match(
      new RegExp('(^| )' + name + '=([^;]+)')
    );
    return match ? match[2] : '';
  }
  public purgeCookie(name: string): void {
     document.cookie = name + '="";Expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
  }
}
