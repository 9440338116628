import { GetterTree } from 'vuex';
import { StateInterface } from '../index';
import { AuthStateInterface} from './state';

export type Getters = {
  getUsername(state: AuthStateInterface): string
 }
const getters: GetterTree<AuthStateInterface, StateInterface> & Getters = {
  getUsername(state): string {
    return state.username;
  },
};

export default getters;
