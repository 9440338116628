import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [{ path: '', component: () => import('pages/NoUser.vue') }],
  },
  {
    path: '/register',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/Register.vue') },
      { path: 'user', component: () => import('pages/RegisterUser.vue') },
      { path: 'company', component: () => import('pages/RegisterCompany.vue') },
    ],
  },
  {
    path: '/login',
    component: () => import('layouts/MainLayout.vue'),
    children: [{ path: '', component: () => import('pages/Login.vue') }],
  },

  {
    path: '/challenges',
    meta: { requiresAuth: true},
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/ChallengeList.vue') }
    ],
  },
  {
    path: '/history/:id',
    meta: { requiresAuth: true},
    component: () => import('layouts/MainLayout.vue'),
    children: [{ path: '', component: () => import('pages/History.vue') }],
  },
  {
    path: '/notifications',
    meta: { requiresAuth: true},
    component: () => import('layouts/MainLayout.vue'),
    children: [{ path: '', component: () => import('pages/Notification.vue') }],
  },

  {
    path: '/challenge/:id',
    meta: { requiresAuth: true, layoutTitle: 'challenge' },
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: 'details',
        path: 'details',
        component: () => import('pages/ChallengeDetails.vue'),
      },
      {
        name: 'valider_activite',
        path: 'valider_activite',
        component: () => import('pages/ValidateChallenge.vue'),
      },
      {
        name: 'valider_association',
        path: 'valider_association',
        component: () => import('pages/ValidateAssociation.vue'),
      },
      {
        path: 'valider_association_liste',
        component: () => import('pages/ValidateAssociationList.vue'),
      },
      {
        path: 'valider_don',
        component: () => import('pages/ValidateDonation.vue'),
      },
      {
        path: 'update_activity',
        component: () => import('pages/updateChallenge/UpdateActivity.vue'),
      },
      {
        path: 'update_donation',
        component: () => import('pages/updateChallenge/UpdateDonation.vue'),
      },
      {
        path: 'update_association',
        component: () => import('pages/updateChallenge/UpdateAssociation.vue'),
      },
    ],
  },

  {
    path: '/addchallenge',
    meta: { requiresAuth: true, layoutTitle: 'challenge' },
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: 'activity',
        component: () => import('pages/addChallenge/Activity.vue'),
      },
      {
        path: 'donation',
        component: () => import('pages/addChallenge/Donation.vue'),
      },
      {
        path: 'association',
        component: () => import('pages/addChallenge/Association.vue'),
      },
      {
        path: 'confirm',
        component: () => import('pages/addChallenge/Confirm.vue'),
      },
      {
        path: 'thanks',
        component: () => import('pages/addChallenge/Thanks.vue'),
      },
    ],
  },
  {
    path: '/parameters',
    meta: { requiresAuth: true, layoutTitle: 'paramètres' },
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/parameters/Parameters.vue') },
      { path: 'cgu', component: () => import('pages/parameters/Cgu.vue') },
      { path: 'userdetails', component: () => import('pages/parameters/UserDetails.vue') },
      { path: 'companydetails', component: () => import('pages/parameters/CompanyDetails.vue') },
      { path: 'contact', component: () => import('pages/parameters/Contact.vue') },
      { path: 'update_userdetails', component: () => import('pages/UpdateUserDetails.vue') },
      { path: 'update_companydetails', component: () => import('pages/parameters/UpdateCompanyDetails.vue') },
    ], 
  },
  {
    path: '/patron',
    meta: { requiresAuth: true, layoutTitle: 'parrainage' },
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: 'usersearch', component: () => import('pages/patronage/UserSearchPage.vue') },
      { path: 'userdetails/:id', component: () => import('pages/patronage/UserDetailsPage.vue') },
      { name: 'patronage_details', path: ':id/details', component: () => import('pages/patronage/PatronageDetails.vue') },
      { name: 'validate_patronage', path: ':id/validate', component: () => import('pages/patronage/ValidatePatronage.vue') },
      { path: 'thanks', component: () => import('pages/patronage/Thanks.vue') },
    ],
  },
  {
    path: '/forgot-password',
    component: () => import('layouts/MainLayout.vue'),
    children: [{ path: '', component: () =>import('pages/ForgottenPassword.vue')}],
  },
  {
    path: '/reset-password/:code',
    component: () => import('layouts/MainLayout.vue'),
    children: [{ path: '', component: () =>import('pages/ResetPassword.vue')}],
  },
  // {
  //   path: '/challengeList',
  //   component: () => import('layouts/MainLayout.vue'),
  //   children: [{ path: '', component: () => import('pages/ChallengeList.vue') }],
  // },
  // {
  //   path: '/addChallenge',
  //   component: () => import('layouts/MainLayout.vue'),
  //   children: [
  //     { path: '/activity', component: () => import('pages/AddChallengeActivity.vue') },
  //     { path: '/donation', component: () => import('pages/AddChallengeDonation.vue') },
  //     { path: '/association', component: () => import('pages/AddChallengeAssociation.vue') },
  //     { path: '/confirm', component: () => import('pages/AddChallengeConfirm.vue') }
  // ],
  // },

  // Always leave this as last one,
  // but you can also remove it

  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue'),
  },
  {
    path: '/add_donation',
    meta: { requiresAuth: true, layoutTitle: 'don' },
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: 'amount',
        component: () => import('pages/addDonation/Amount.vue'),
      },
      {
        path: 'associationDonation',
        component: () => import('pages/addDonation/AssociationDonation.vue'),
      },
      {
        path: 'confirmDonation',
        component: () => import('pages/addDonation/ConfirmDonation.vue'),
      },
      {
        path: 'thanks',
        component: () => import('pages/addDonation/Thanks.vue'),
      },
    ],
  },
  {
    path: '/payment',
    meta: { requiresAuth: true },
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: 'success',
        component: () => import('pages/PaymentSuccess.vue'),
      }
    ],
  },
];

export default routes;
