import { AxiosResponse } from 'axios';
import { api } from '../boot/axios';
import { User} from './../components/models';

class UserService {
  getUser(): Promise<AxiosResponse<User>> {
    return api.get('/api/users/me');
  }
  findUser(id: string): Promise<AxiosResponse<User>> {
    return api.get('/api/users/' + id);
  }
  updateCurrentUser(query: {data : User}) {
    return api.post('/api/user/updateLoggedInUser', JSON.stringify(query));
  }
  usernameFilter(username: string): Promise<AxiosResponse<boolean>> {
    const data = {
      pseudo: username
    }
    return api.post('/api/user/pseudoExist', JSON.stringify(data));
  }
  usernameFilterLogged(username: string): Promise<AxiosResponse<User[]>> {
    return api.get('/api/users?filters[username][$eq]=' + username);
  }
  mailFilter(email: string): Promise<AxiosResponse<boolean>>{
    const data = {
      email: email
    }
    return api.post('api/user/emailExist', JSON.stringify(data));
  }
  mailFilterLogged(email: string): Promise<AxiosResponse<User[]>>{
    return api.get('api/users?filters[email][$eq]=' + email);
  }
  userSearch(query: string): Promise<AxiosResponse<User[]>> {
    return api.get('/api/users?filters[$or][0][username][$contains]=' + query + '&filters[$or][1][firstname][$contains]=' + query + '&filters[$or][2][lastname][$contains]=' + query);
  }
  updateUser(id: string, user: User): Promise<AxiosResponse<User>> {
    return api.put('/api/users/' + id, JSON.stringify(user));
  }
}
export default new UserService();
