import { Register } from './../../components/models';
import { MutationTree } from 'vuex';
import { AuthStateInterface } from './state';
import { User } from './../../components/models';

const mutation: MutationTree<AuthStateInterface> = {
  registerRequest(state, user: Register) {
    state.email = user.email
    state.username = user.username
  },
  loginMutation(state, user: User) {
    state.username = user.username
    state.email = user.email
    state.logged = true
    state.jwt = user.jwt
  }
};

export default mutation;
