import { boot } from 'quasar/wrappers';
import axios, { AxiosError, AxiosInstance } from 'axios';
import Cookie from '../utilities/Cookie';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
  }
}

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)
const api = axios.create({ baseURL: process.env.API });
api.interceptors.request.use(
  (config) => {
    const cookie = new Cookie;
    const token = cookie.getCookie('jwt');
    if (token) {
      config.headers = { Authorization : 'Bearer ' + token, 'Content-type' : 'application/json'};
    }
    else {
      config.headers = { 'Content-type' : 'application/json'};
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    const cookie = new Cookie();
    const jwt = cookie.getCookie('jwt');
    const rememberMe = cookie.getCookie('rememberMe');
    if (jwt && !rememberMe) cookie.setCookie('jwt', jwt, 30);

    return response;
  },
  async function(error:AxiosError) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // const originalRequest = error.config;
    // console.log(originalRequest);
    // console.log(error.response?.status);
    // if (error.response?.status === 401 && originalRequest.url?.includes("auth/jwt/refresh/") )
    // {
    //   // store.commit("clearUserData");
    //   // router.push("/login");
    //   return Promise.reject(error);
    // } else if (error.response?.status === 401 && !originalRequest._retry) {
    //   originalRequest._retry = true;
    //   console.log(error.response.status);
    //   // await store.dispatch("refreshToken");
    //   return axios(originalRequest as AxiosRequestConfig);
    // }
    return Promise.reject(error);
  }
);

export default boot(({ app }) => {
  // for use inside Vue files (Options API) through this.$axios and this.$api

  app.config.globalProperties.$axios = axios;
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api;
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API
});

export { api };
